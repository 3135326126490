import axiosCreator from "axios";
import { toast } from "react-toastify";

export let axios = axiosCreator.create({
  baseURL: process.env.REACT_APP_URL ?? "http://localhost:1337",
  // baseURL: process.env.REACT_APP_URL ?? "http://10.101.72.34:1337",
  // baseURL: process.env.REACT_APP_URL ?? "https://api.serkohls.com",
});

console.log("ENV-REACT_APP_URL:", process.env.REACT_APP_URL);
let navigator = null;

export const setMyAxiosNavigator = (_navigator) => {
  navigator = _navigator;
};
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("Error occurred in axios", error);
    const data = error.response.data;
    if (error.response.status === 413) {
      toast.error("File size is too large");
    }
    if (error.response.status === 401) {
      let errorMessage = data.message;

      // If user profile visited from qr code
      let endpoint = error.response.config?.url;
      let queryString = error.response.config?.url?.split('?')[1];
      if(endpoint?.slice(0, 6) === '/user/' && new URLSearchParams(queryString).get('source') === 'qr')
        errorMessage = "Please login or create an account to visit user profiles";

      localStorage.removeItem("token");
      if (navigator)
        navigator(
          "/login",
          {
            replace: true,
            state: { message: errorMessage }
          },
        );
      // toast.error(data.message || data);
    } else toast.error(data.message || data);
    return Promise.reject(error);
  }
);

export default axios;
