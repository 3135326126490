import clsx from "clsx";
import Layout from "../../Layout";
import axios from "../../MyAxios";
import {
  GET_ALL_USERS,
  GET_GROUP,
  GET_USER_CHAT,
  GROUP_ADD_MEMBERS,
  GROUP_INFO,
  ROOT,
} from "../../constants/api";
import { styled, alpha } from "@mui/material/styles";
import {
  Avatar,
  Checkbox,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import Title from "@components/Title";
import InputBase from "@mui/material/InputBase";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#626262",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  border: "2px solid",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: 15,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const AddMembers = () => {
  const navigation = useNavigate();
  const [users, setUsers] = useState([]);
  const classes = useStyles();
  const { state } = useLocation();
  const { groupId } = state;
  const [selectedUsers, setSelectedUsers] = useState({});
  const [name, setName] = useState("");
  const [query, setQuery] = useState("");
  const [allSelected, setAllSelected] = useState(false);
  const { userRecord } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const searchUsers = () => {
    if(!name)
      return toast.error('You need to enter a username');

    setLoading(true);

    axios.get('/user/get-user-by-usernames', { params: { usernames: [ name ] } })
      .then(response => setUsers(response.data))
      .finally(() => setLoading(false));
  }

  const isSelected = (id) => Boolean(selectedUsers[id]);

  const addMember = async () => {
    try {
      Object.keys(selectedUsers).forEach((userId) =>
        axios
          .post("/notification/create/group-invitation", {
            fromGroup: groupId,
            forUser: userId,
          })
          .catch(() => {})
      );
      // toast.success("Invitation Request Successfully!");
      navigation(`${GROUP_INFO}/${groupId}`);
    } catch (error) {}
  };

  const addToSelectedUsers = (user) => {
    setAllSelected(false);

    if (!Boolean(selectedUsers[user.id]))
      setSelectedUsers({ ...selectedUsers, [user.id]: user });
    else
      removeFromSelectedUsers(user);
  };

  const removeFromSelectedUsers = (user) => {
    let newSelectedUsers = { ...selectedUsers };
    delete newSelectedUsers[user.id];
    setSelectedUsers(newSelectedUsers);
  };

  const selectAll = () => {
    if (allSelected)
      setSelectedUsers({});
    else {
      let usersSet = {};
      users.forEach(user => usersSet[user.id] = user);
      setSelectedUsers(usersSet);
    }
    setAllSelected(!allSelected);
  };

  return (
    <Layout>
      <Title back={ROOT}>Add Members</Title>
      <div className="m-4">
        <Search className="flex mb-5 justify-between items-center">
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <PersonSearchIcon
            color="primary"
            onClick={searchUsers}
            className="mr-4 cursor-pointer"
          />
        </Search>

        <Typography color="text.dark">Add Members</Typography>

        {Boolean(Object.keys(selectedUsers)?.length) && (
          <Typography color="text.dark">Selected members: {Object.values(selectedUsers).map(user => user.userName).join(', ')}</Typography>
        )}

        {(() => {
          if(loading)
            return (
              <div className="mt-5 flex justify-center">
                <CircularProgress />
              </div>
            );

          return (
            <>
              <div className="mt-5 flex flex-col">
                <label className={clsx(classes.textGrey)}>
                  <Checkbox
                    sx={{ color: "green" }}
                    checked={allSelected}
                    onChange={(e) => selectAll(e)}
                  />
                  Select All
                </label>
              </div>
              {users.slice(0, 6)?.map((user, key) => (
                <div key={key}>
                  {userRecord?.id !== user?.id && (
                    <label key={user?.id} className={clsx(classes.textGrey, " flex")}>
                      <Checkbox
                        sx={{ color: "green" }}
                        checked={isSelected(user.id)}
                        onChange={(e) => addToSelectedUsers(user)}
                      />
                      <div className=" flex w-full flex-row items-center border-b-2 p-2">
                        <div>
                          <Avatar
                            className=" h-10 w-10 rounded-full"
                            src={user?.media?.avatarUrl}
                          />
                        </div>
                        <div className="flex w-full justify-between p-2 pl-4">
                          <div>
                            <Typography
                              className={clsx(classes.textGrey, "text-xs font-bold")}
                            >
                              {user?.firstName} {user?.lastName}
                            </Typography>
                            <Typography
                              className={clsx(classes.textGrey, " leading-none")}
                            >
                              {user?.userName}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </label>
                  )}
                </div>
              ))}
              {Boolean(Object.keys(selectedUsers)?.length) && (
                <div className="flex items-end justify-end">
                  <div
                    onClick={addMember}
                    className={clsx(
                      classes.button,
                      "mr-10 mt-5 rounded-3xl p-2 px-6 cursor-pointer"
                    )}
                  >
                    Done
                  </div>
                </div>
              )}
            </>
          );
        })()}
      </div>
    </Layout>
  );
};

export default AddMembers;
