import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import Button from "../../components/Button";
import { useEffect, useState } from "react";
import Constraints from "../../components/Constraints";
import { toast } from "react-toastify";
import axios from "../../MyAxios";
import { Link, useNavigate } from "react-router-dom";
import { LOGIN, PERSONEL_DETAILS, USERNAME } from "../../constants/api";
import LayoutEntrance from "../../LayoutEntrance";
import BottomText from "../../components/BottomText";
import { setUser } from "../../redux/userSlice";
import mentee from "@images/logo.png";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 1,
    borderColor: "#887E7E",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
}));

const Username = () => {
  const classes = useStyles();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");

  // Form submission handler
  const onSubmit = async (e) => {
    e.preventDefault();
    if (disabled) {
      toast.error(
        "Please adjust your username to meet our security requirements"
      );
    } else {
      dispatch(setUser(username));
      try {
        await axios.post(USERNAME, { userName: username });
        navigation(PERSONEL_DETAILS);
      } catch (error) {
        setError(error.response.data);
      }
    }
  };

  const handleChange = (e) => {
    let value = e.target.value;
    setUsername(value);
  };
  const setDisabledSU = (e) => console.log();
  const navigate = useNavigate();

  useEffect(() => {
    // clear session storage used for message on /login
    sessionStorage.clear();
  }, [])

  return (
    <LayoutEntrance>
      <div className="mt-20">
        <Typography
          variant="h4"
          className={clsx(classes.textColor, " text-center font-extrabold mt-4")}
        >
          Sign Up for
        </Typography>
        <img
          src={mentee}
          alt="mentee"
          className="mx-auto mb-2"
          style={{ height: "30px" }}
        />
      </div>
      <form onSubmit={onSubmit} className="mt-10 flex flex-col gap-14">
        <div className="mt-3 flex flex-col">
          <input
            className={clsx(classes.border, "rounded-md p-3")}
            placeholder="Create your username"
            type="text"
            onChange={handleChange}
          />
          <Constraints
            string={username}
            setDisabled={setDisabled}
            setDisabledSU={setDisabledSU}
            forPass={false}
          />
        </div>

        <Button>Next</Button>
      </form>
      <div className="flex gap-2 items-center justify-center mt-4">
        <Typography fontSize={18}>Already have an account?</Typography>
        <div onClick={() => navigate(LOGIN)}>
          <Typography color="primary">Login</Typography>
        </div>
      </div>
      <div className="flex justify-center gap-2">
        <Link to={"/user-agreement"}>
          <Typography className="cursor-pointer text-center text-xs underline">
            Terms and Conditions
          </Typography>
        </Link>
        <Typography>|</Typography>
        <Link to={"/privacy-policy"}>
          <Typography className="cursor-pointer text-center text-xs underline">
            Privacy Policy
          </Typography>
        </Link>
      </div>
    </LayoutEntrance>
  );
};

export default Username;
