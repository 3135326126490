import React from 'react';
import { ActionFilters } from "@hooks/useActionFilters";
import axios from "@MyAxios";
import { setUserRecord } from "@redux/userSlice";
import dayjs from "dayjs";
import { toast } from "react-toastify";

export const DateOptions = Object.freeze({
  ANY: "All",
  TODAY: "Today",
  THIS_WEEK: "This Week",
  THIS_MONTH: "This Month",
});

export const ReportFilters = Object.freeze({
  RESOLVED: "Resolved",
  UNRESOLVED: "Unresolved",
});
export function getLastDate(filter) {
  let currentDate = new Date();

  if (filter === DateOptions.TODAY) {
    return currentDate.setHours(0, 0, 0, 0).valueOf();
  } else if (filter === DateOptions.THIS_WEEK) {
    // Set the date to the last day of the current week
    // currentDate.setDate(currentDate.getDate() - (7 - currentDate.getDay()));
    // currentDate.setHours(23, 59, 59, 999);

    return new Date(currentDate.valueOf() - 7 * 24 * 60 * 60 * 1000).valueOf();
  } else if (filter === DateOptions.THIS_MONTH) {
    // currentDate.setMonth(currentDate.getMonth() - 1, 1);
    // currentDate.setHours(23, 59, 59, 999);
    return new Date(currentDate.valueOf() - 30 * 24 * 60 * 60 * 1000).valueOf();
  }
}

export function getSelectedItems(items) {
  return Object.keys(items).filter((key) => items[key]);
}

export const calTime = (timestamp) => {
  const day1 = dayjs(timestamp);
  const day2 = dayjs();
  let time = 0;

  time = day2.diff(day1, "s");
  if (time < 60) {
    time = time < 0 ? 0 : time;
    return `${time} s`;
  }

  time = day2.diff(day1, "m");
  if (time < 60) {
    return `${time} m`;
  }

  time = day2.diff(day1, "h");
  if (time < 24) {
    return `${time} h`;
  }
  var options = { day: "2-digit", month: "long", year: "numeric" };
  return new Date(timestamp).toLocaleDateString("en-US", options);

  // time = day2.diff(day1, "d");
  // if (time < 31) {
  //   return `${time} days ago`;
  // }

  // time = day2.diff(day1, "M"); // Use 'M' for months
  // if (time < 12) {
  //   return `${time} months ago`;
  // }

  // time = day2.diff(day1, "y");
  // return `${time} years ago`;
};
export function getModelStatus(model) {
  if (model.disabledAt > 0) {
    return "Disabled";
  } else if (model.deletedAt > 0) {
    return "Deleted";
  } else {
    return "Active";
  }
}

export function getModelActionDate(model) {
  if (model.disabledAt > 0) {
    return formatDate(model.disabledAt);
  } else if (model.deletedAt > 0) {
    return formatDate(model.deletedAt);
  } else {
    return null;
  }
}

export function formatDate(date) {
  return new Date(date).toLocaleDateString("en-GB");
}

export function createDateColumn(actionFilter) {
  switch (actionFilter) {
    case ActionFilters.DELETED:
      return {
        columnName: "Deleted At",
        field: "deletedAt",
        render: (item) => new Date(item.deletedAt).toLocaleDateString("en-GB"),
      };
    case ActionFilters.DISABLED:
      return {
        columnName: "Disabled At",
        field: "disabledAt",
        render: (item) => new Date(item.disabledAt).toLocaleDateString("en-GB"),
      };
    default:
      return {
        columnName: "Created At",
        field: "createdAt",
        render: (item) => new Date(item.createdAt).toLocaleDateString("en-GB"),
      };
  }
}

export const trunc = (str, length = 30) => {
  if(!str)
    return '';

  if (str.length > length)
    return str.slice(0, length) + "...";

  return str;
};

export const updateToast = (message, type, toastId) => {
  toast.update(
    toastId,
    { render: message, type, isLoading: false, autoClose: 5000, draggable: true, closeOnClick: true }
  )
};

export const refreshUserRecord = (dispatch, callback) => {
  axios.get(`/user`).then((res) => {
    dispatch(setUserRecord(res.data));
  })
    .then(() => callback?.());
}

export const parseAlphabets = (text) => text?.match(/[a-zA-Z]/g)?.join('');

// Recursive function to convert DOM nodes to React elements
const convertNodeToReact = (node, key) => {
  if (node.nodeType === Node.TEXT_NODE) {
    return node.textContent; // Return plain text
  }

  if (node.nodeType === Node.ELEMENT_NODE) {
    const { tagName, attributes, childNodes } = node;

    const props = { key };
    Array.from(attributes).forEach((attr) => {
      props[attr.name] = attr.value;
    });

    const children = Array.from(childNodes).map((child, childKey) =>
      convertNodeToReact(child, `${key}-${childKey}`)
    );

    return React.createElement(tagName.toLowerCase(), props, ...children);
  }

  return null;
};

export const parseHTML = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return Array.from(doc.body.childNodes).map((node, index) => convertNodeToReact(node, index));
};

export const isDateInvalid = (date) => {
  return isNaN(dayjs(date).toDate().getTime());
}

export const regexReplacers = {
  script: {
    exp: /<script.*?<\/script>/gs,
    rep: ''
  },
  style: {
    exp: /<style.*?<\/style>/gs,
    rep: ''
  },
  emoji: {
    exp: /\p{RGI_Emoji}/vg,
    rep: (emoji) => `<span class="noto-font">${emoji}</span>`
  }
}