import { CardMedia, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";
import send from "@images/send.svg";
import cameraChat from "@images/cameraChat.svg";
import { MentionsInput, Mention } from "react-mentions";
import mentionStyle from "./mentionStyles";
import mentionsInputStyle from "./mentionsInputStyles";
import MyAvatar from "../admin/MyAvatar";
import "./CommentInput.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "@MyAxios";
import EmojiSelector from "@components/emoji-selector/EmojiSelector";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#626262",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
  bgGreen: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
}));

export default function CommentInput({
  onSubmit,
  value,
  setValue,
  setFile,
  prevFile,
  setPrevFile,
  loading,
}) {
  const classes = useStyles();
  const fileInput = useRef(null);
  
  const [prevFileType, setPrevFileType] = useState();
  const [mentionSuggestions, setMentionSuggestions] = useState([]);
  const [mentionSuggestionsLoading, setMentionSuggestionsLoading] = useState(false);

  const [cursorPos, setCursorPos] = useState(0);

  const onChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 3 * 1024 * 1024) {
        toast.error("File size should be less than 3MB");
        return;
      }
      setFile(e.target.files[0]);
      setPrevFile(URL.createObjectURL(e.target.files[0]));
      setPrevFileType(e.target.files[0].type.split("/")[0]);
    }
    if (e.target.value) e.target.value = null;
  };

  const getRepliedToUser = (mention) => {
    const endIndex = mention.indexOf("]", 2);
    return mention.slice(2, endIndex);
  }

  const getRenderedValue = () => {
    const matchRegex = /\@\[([a-zA-Z0-9]+)\]\(([a-zA-Z0-9]+)\)/gi;

    return {
      renderedText: value?.replace(matchRegex, getRepliedToUser),
      replacers: value?.match(matchRegex)?.reduce((acc, curr) => ({...acc, [getRepliedToUser(curr)]: curr}), {}) ?? {}
    };
  }
  
  const handleSelectEmoji = (emoji) => {
    const { renderedText, replacers } = getRenderedValue();
    const valueArray = (renderedText ?? '').split('');
    valueArray.splice(cursorPos, 0, emoji);
    let newValue = valueArray.join('');

    Object.keys(replacers).forEach(username => {
      newValue = newValue.replace(new RegExp(username, "gi"), replacers[username]);
    });

    setValue(newValue);
  }

  useEffect(() => {
    if(value) {
      // update further mentions
      let mentionStrings = value.match(/\B@[a-z0-9_-]+/gi);

      setMentionSuggestions([]);

      if(mentionStrings?.length) {
        mentionStrings = mentionStrings.map(str => str.slice(1));

        setMentionSuggestionsLoading(true);

        axios.get('/user/get-user-by-usernames', { params: { usernames: mentionStrings } })
          .then(response => {
            setMentionSuggestions(response.data);
          })
          .finally(() => setMentionSuggestionsLoading(false));
      }
    }
  }, [value])

  useEffect(() => {
  }, [])

  return (
    <div className="fixed bottom-0 flex flex-col w-full items-center justify-center md:w-6/12 bg-white">
      {prevFile && (
        <div
          className={clsx(
            `my-2 flex flex-col relative rounded-xl p-6`,
            classes.bgGreen
          )}
        >
          <Typography
            color={"red"}
            onClick={() => {
              setFile("");
              setPrevFile("");
              setPrevFileType("");
            }}
            className="cursor-pointer absolute top-3 left-3"
          >
            X
          </Typography>
          <div>
            {prevFileType === "video" ? (
              <CardMedia
                controlsList="nodownload"
                crossOrigin="anonymous"
                component={"video"}
                controls
                className="w-52 h-40 object-contain"
                image={prevFile}
                alt="Stream Media"
                autoPlay
                loop
                muted
              />
            ) : (
              <div>
                <img
                  src={prevFile}
                  className="w-52 h-40 object-contain"
                  alt="Camera Chat"
                />
              </div>
            )}
          </div>
        </div>
      )}
      <form onSubmit={onSubmit} className="flex items-center p-4 relative">
        <input
          className={clsx("hidden")}
          multiple={true}
          type="file"
          accept="image/*,video/*"
          ref={fileInput}
          onChange={(e) => onChange(e)}
        />
        <img
          src={cameraChat}
          alt="Camera Chat"
          onClick={() => fileInput.current.click()}
        />
        <MentionsInput
          value={value}
          style={mentionsInputStyle}
          className={clsx("mx-2 w-72 noto-font")}
          placeholder="Comment"
          allowSuggestionsAboveCursor
          onSelectCapture={e => setCursorPos(e.target.selectionStart)}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        >
          {/* TODO: Link api call to Mentions library for accurate matching */}
          <Mention
            trigger="@"
            appendSpaceOnAdd
            style={mentionStyle}
            key={mentionSuggestionsLoading}
            isLoading={mentionSuggestionsLoading}
            data={mentionSuggestions?.map(user => ({ id: user.userName, display: user.userName }))}
            renderSuggestion={(suggestion, search, value, index, focused) => (
              <MyAvatar name={value} src={mentionSuggestions?.find(user => user.userName === suggestion.id)?.media?.avatarUrl} />
            )}
          />
        </MentionsInput>
        <EmojiSelector
          className='-ml-12 mr-3'
          onSelection={handleSelectEmoji}
        />
        {/* <TextField
          type="text"
          size="small"
          id="outlined-basic"
          fullWidth
          value={value}
          placeholder="Comment"
          InputProps={{
            sx: {
              borderRadius: 10,
              borderColor: theme.palette.primary.main,
              borderWidth: 1,
            },
          }}
          className="mx-2 w-72"
          onChange={(e) => setValue(e.target.value)}
        /> */}
        {loading ? (
          <CircularProgress />
        ) : (
          <button className="w-16" type="submit">
            <img src={send} alt="Send" />
          </button>
        )}
      </form>
    </div>
  );
}
