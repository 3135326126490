import React, { useEffect, useRef, useState } from "react";
import { Autocomplete, Button, MenuItem, TextField, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import FilterSelect from "./FilterSelect";
import {
  FilterList,
  ExpandMore as ExpandMoreIcon
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { setFilterFromDate, setFilterSelectedTags, setFilterToDate } from "@redux/userSlice";
import dayjs from 'dayjs';
import { isDateInvalid } from "@helpers";

const useStyles = makeStyles((theme) => ({
  filtersContainer: {
    borderWidth: 1,
    minWidth: 300,
  },
  border: {
    borderWidth: 1,
    borderColor: "#D1D1D1 !important",
  },
}));

function HomepageFilter({
  handleCommunityChange,
  handleGroupChange
}) {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  const dateSelectorRef1 = useRef();
  const dateSelectorRef2 = useRef();

  const {
    userRecord,
    filterSelectedTags,
    filterFromDate,
    filterToDate,
  } = useSelector((state) => state.user);

  const [filtersOpen, setFiltersOpen] = useState(false);

  const [tagsToShow, setTagsToShow] = useState([]);

  const handleFiltersClick = () => {
    if(filtersOpen)
      document.body.style.overflow = 'auto';
    else
      document.body.style.overflow = 'hidden';

    setFiltersOpen(!filtersOpen);
  }

  const getSelectedCommunity = () => {
    if(!localStorage.homepageCommunity || !userRecord?.joinedCommunities)
      return '';

    return userRecord?.joinedCommunities?.find(com => com.id == localStorage.homepageCommunity)?.id ?? '';
  }

  const getSelectedGroup = () => {
    if(!localStorage.homepageGroups || !userRecord?.allGroups)
      return [];

    let selected = JSON.parse(localStorage.homepageGroups);

    return userRecord?.allGroups?.filter(g => selected.includes(g.id))?.map(g => g.id) ?? [];
  }

  const getFilterGroups = () => {
    let groups = [];

    if(userRecord?.allGroups) {
      groups = userRecord?.allGroups;
      let selectedCommunity = getSelectedCommunity();

      if(selectedCommunity)
        groups = groups.filter(group => group.community === selectedCommunity);
    }

    return groups;
  }

  const handleTagChange = (newSelectedTags) => {
    if(Boolean(newSelectedTags?.length) && userRecord?.showPreferredContent && localStorage.profileTagsOverrideConsent !== 'true') {
      if(!window.confirm('This selection will bypass your preferred tags selection. Are you sure you want to continue?'))
        return;

      localStorage.profileTagsOverrideConsent = true;
    }

    dispatch(setFilterSelectedTags(newSelectedTags));
    localStorage.homepageTags = JSON.stringify(newSelectedTags);
  }

  const filterSelectExpandMoreIcon = (props) => <ExpandMoreIcon {...props} sx={{ color: '#9F9F9F !important' }} />

  const updateTagsToShow = () => {
    if(userRecord?.allTags) {
      // When user leaves a community, their selected tags for that community will be unselected
      const storedTags = JSON.parse(localStorage.getItem('homepageTags') || '[]');
      let validTags = userRecord?.allTags?.map(tag => tag.subcategory) ?? [];

      let selectedCommunity = getSelectedCommunity();
      if(selectedCommunity)
        validTags = userRecord?.allTags?.filter(tag => tag.community === selectedCommunity)?.map(tag => tag.subcategory) ?? [];

      const filteredTags = storedTags.filter(tag => validTags.includes(tag));
      localStorage.setItem('homepageTags', JSON.stringify(filteredTags));
      dispatch(setFilterSelectedTags(filteredTags));

      setTagsToShow(
        validTags?.filter((value, index, self) => self.indexOf(value) === index) ?? []
      );
    }
  }

  useEffect(() => {
    updateTagsToShow();
  }, [userRecord])

  const dateSelectorSlotProps = {
    field: {
      clearable: true,
    },
    textField: {
      size: 'small',
      placeholder: 'Select date',
      onKeyDown: (e) => e.preventDefault(),
      InputProps: {
        color: 'primary',
        size: 'small',
        classes: {
          notchedOutline: clsx(classes.border, "rounded-md p-3"),
        },
      }
    }
  }

  const dateSelectorSlots = {
    textField: (props) => {
      const dateIsInvalid = isDateInvalid(props.value);

      return (
        <TextField
          {...props}
          value={dateIsInvalid ? '' : props.value}
          onClick={(e) => {
            if(e.target.nodeName === 'INPUT') {
              e.currentTarget.blur();
              e.currentTarget.closest('.MuiFormControl-root')?.querySelector('.MuiIconButton-edgeEnd')?.click();
            }
          }}
          inputProps={{ inputMode: 'none' }}
        />
      )
    }
  }

  const handleClear = () => {
    // Communities
    handleCommunityChange('');
    updateTagsToShow();

    // Groups
    handleGroupChange([]);

    // Tags
    handleTagChange([]);

    // Dates
    dispatch(setFilterFromDate(null));
    dispatch(setFilterToDate(null));
  }

  const filterIsApplied = 
    getSelectedCommunity()?.length
    || getSelectedGroup()?.length
    || filterSelectedTags?.length
    || Boolean(filterFromDate)
    || Boolean(filterToDate)

  return (
    <div className="flex flex-col items-end relative">
      <Button
        variant="outlined"
        size="small"
        sx={
          filterIsApplied
          ? {
            backgroundColor: '#63f7c1',
            boxShadow: `0 0 5px ${theme.palette.primary.light}`,
          }
          : { borderColor: '#CCCCCC' }
        }
        startIcon={<FilterList />}
        onClick={handleFiltersClick}
      >
        <Typography className="normal-case">
          Filters
        </Typography>
      </Button>

      {filtersOpen && (
        <div
          onClick={handleFiltersClick}
          className="fixed h-screen w-screen z-10 left-0 top-0 overscroll-none"
        />
      )}

      <div className={clsx(
        "absolute right-0 top-10 rounded-xl bg-white border-[#D1D1D1] p-4 flex flex-col gap-4 shadow-md z-20",
        classes.filtersContainer,
        filtersOpen ? 'block' : 'hidden'
      )}>
        {(userRecord?.joinedCommunities?.length > 1) && (
          <FilterSelect
            size='small'
            displayEmpty
            value={getSelectedCommunity()}
            // className="w-full sm:w-1/2"
            onChange={e => {
              handleCommunityChange(e.target.value);
              updateTagsToShow();
            }}
            IconComponent={filterSelectExpandMoreIcon}
          >
            <MenuItem value=''>All Communities</MenuItem>
            {userRecord?.joinedCommunities?.map(community => (
              <MenuItem value={community.id}>
                {community.name}
              </MenuItem>
            ))}
          </FilterSelect>
        )}

        {(userRecord?.allGroups?.length > 0 && getFilterGroups()?.length > 0) && (
          <FilterSelect
            multiple
            size='small'
            displayEmpty
            value={getSelectedGroup()}
            // className="w-full sm:w-1/2"
            onChange={e => {
              if(e.target.value?.includes(''))
                handleGroupChange([]);
              else
                handleGroupChange(e.target.value);
            }}
            renderValue={value => {
              if(value?.length == 0)
                return 'All Groups';
              else if(value?.length == 1)
                return userRecord?.allGroups?.find(g => g.id == value[0])?.name;
              else
                return value?.length + ' Groups';
            }}
            IconComponent={filterSelectExpandMoreIcon}
          >
            <MenuItem value=''>
              All Groups
            </MenuItem>
            {getFilterGroups()?.map(group => (
              <MenuItem value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </FilterSelect>
        )}

        {Boolean(tagsToShow?.length) && (
          <Autocomplete
            size='small'
            multiple
            clearIcon={null}
            disableCloseOnSelect
            options={tagsToShow}
            getOptionLabel={(option) => option}
            value={filterSelectedTags}
            onChange={(e, selectedTags) => handleTagChange(selectedTags)}
            ListboxProps={{ style: { overscrollBehavior: 'none' } }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tags"
                variant="outlined"
                placeholder="Search tags"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px'
                  }
                }}
              />
            )}
          />
        )}

        <div ref={dateSelectorRef1}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label='From'
              format="DD MMM, YYYY"
              className="w-full"
              value={filterFromDate ? dayjs(filterFromDate) : null}
              maxDate={filterToDate ? dayjs(filterToDate, 'YYYY-MM-DD') : undefined}
              onChange={(date) => {
                const formattedDate = date ? date.format('YYYY-MM-DD') : null;
                dispatch(setFilterFromDate(formattedDate));
              }}
              slots={dateSelectorSlots}
              slotProps={{
                ...dateSelectorSlotProps,
                popper: {
                  anchorEl: dateSelectorRef1.current,
                },
              }}
            />
          </LocalizationProvider>
        </div>

        <div ref={dateSelectorRef2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label='To'
              format="DD MMM, YYYY"
              className="w-full"
              value={filterToDate ? dayjs(filterToDate) : null}
              minDate={filterFromDate ? dayjs(filterFromDate, 'YYYY-MM-DD') : undefined}
              onChange={(date) => {
                const formattedDate = date ? date.format('YYYY-MM-DD') : null;
                dispatch(setFilterToDate(formattedDate));
              }}
              slots={dateSelectorSlots}
              slotProps={{
                ...dateSelectorSlotProps,
                popper: {
                  anchorEl: dateSelectorRef2.current,
                },
              }}
            />
          </LocalizationProvider>
        </div>

        <div className="flex justify-end">
          <Button
            variant="outlined"
            className="py-0.5 normal-case"
            onClick={handleClear}
          >
            Clear
          </Button>
        </div>
      </div>

    </div>
  )
}

export default HomepageFilter;