import React, { useState } from 'react'
import { Dialog, MenuItem, MenuList, Typography } from '@mui/material';
import { SendToMobile as SendToMobileIcon, Share as ShareIcon } from '@mui/icons-material';
import ShareWithConnectionDialog from './ShareWithConnectionDialog';

function ShareDialog({
  post,
  open = false,
  onClose,
}) {
  const [openConnectionDialog, setOpenConnectionDialog] = useState(false);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const shareExternally = () => {
    let content = `${process.env.REACT_APP_BASE_URL ?? "http://localhost:3000"}/post/${post?.id}`;

    if (navigator.share) {
      navigator.share({
        title: content,
        url: content,
      });
    }
  }

  const shareInternally = () => {
    setOpenConnectionDialog(true);
  }

  // Greater than 1 because poster themselves are a connection by default
  const postWasOnlySharedWithConnections = post?.connections?.length > 1 && !post.communities?.length && !post.groups?.length;
  const postGroupIsPrivate = post?.groups?.length > 0 && post.groups[0].visibility === 'Private';

  const shareExternallyDisabled = !isMobile || postGroupIsPrivate || postWasOnlySharedWithConnections;
  const shareInternallyDisabled = postWasOnlySharedWithConnections;

  if(!shareInternallyDisabled && shareExternallyDisabled)
    return (
      <ShareWithConnectionDialog
        post={post}
        open={open}
        onClose={onClose}
        onSuccess={onClose}
      />
    )

  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={open}
      onClose={onClose}
      PaperProps={{
        className: 'rounded-xl'
      }}
    >
      <MenuList>
        <MenuItem onClick={shareExternally} disabled={shareExternallyDisabled}>
          <SendToMobileIcon className='mr-2' color='primary' />

          <Typography className='text-sm'>
            Share externally
          </Typography>
        </MenuItem>
        <MenuItem onClick={shareInternally} disabled={shareInternallyDisabled}>
          <ShareIcon className='mr-2' color='primary' />

          <Typography className=''>
            Share internally
          </Typography>
        </MenuItem>
      </MenuList>

      <ShareWithConnectionDialog
        post={post}
        open={openConnectionDialog}
        onClose={() => setOpenConnectionDialog(false)}
        onSuccess={onClose}
      />
    </Dialog>
  )
}

export default ShareDialog;