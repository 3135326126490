import React, { useState } from 'react'
import clsx from 'clsx';
import { EmojiEmotions as EmojiEmotionsIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import EmojiModal from './EmojiModal';

function EmojiSelector({
  className,
  positionClassName,
  onSelection,
}) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <div className={clsx(className ?? 'absolute', positionClassName ?? 'top-2 right-1')}>
      <IconButton onClick={handleClick}>
        <EmojiEmotionsIcon />
      </IconButton>

      <EmojiModal
        open={open}
        onClose={handleClose}
        onSelection={onSelection}
      />
    </div>
  )
}

export default EmojiSelector;