import React from "react";
import { Close } from "@mui/icons-material";

export default function Tag({ name, onRemove }) {
  return (
    <div
      className="px-2 py-1 rounded-md mr-2 flex gap-4"
      style={{
        backgroundColor: "rgba(98, 98, 98, 0.1)",
      }}
    >
      <div>{name}</div>
      {Boolean(onRemove) && (
        <div onClick={onRemove} className="cursor-pointer">
          <Close sx={{ fontSize: "20px" }} color="primary" />
        </div>
      )}
    </div>
  );
}
