import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { CardMedia, Typography } from "@mui/material";
import { calTime } from "@helpers";
import ImageModal from "./ImageModal";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  bgGreen: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  bgGrey: {
    backgroundColor: "#efefef",
    color: "#000",
  },
  textGrey: {
    color: "#626262",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

function ChatBox({ sender, children, img, createdAt }) {
  const classes = useStyles();
  const [openImageModal, setOpenImageModal] = useState(false);

  return (
    <div>
      <div className="flex w-64 md:w-80 flex-col" style={{ wordWrap: 'break-word' }}>
        <div
          className={clsx(
            `leading-1.5 my-2 flex flex-col ${
              sender
                ? "rounded-s-xl  rounded-se-xl"
                : "rounded-e-xl  rounded-ss-xl"
            } p-4`,
            sender ? classes.bgGreen : classes.bgGrey
          )}
        >
          {img?.avatarUrl &&
            (img?.fileType === "video" ? (
              <CardMedia
                controlsList="nodownload"
                crossOrigin="anonymous"
                component={"video"}
                controls
                className="mx-auto h-52 w-11/12 rounded-2xl object-contain"
                image={img?.avatarUrl}
                alt="Stream Media"
                autoPlay
                muted
              />
            ) : (
              <img
                onClick={() => setOpenImageModal(true)}
                className=" mb-3 h-40 bg-white object-contain cursor-pointer"
                src={img?.avatarUrl}
                alt="Media"
              />
            ))}
          <p className="text-sm font-normal">{children}</p>
          <Typography
            color={"black"}
            className={clsx(" text-right", "text-xs")}
          >
            <small>{calTime(createdAt)}</small>
          </Typography>
        </div>
      </div>
      <ImageModal
        url={img?.avatarUrl}
        open={openImageModal}
        setOpen={setOpenImageModal}
      />
    </div>
  );
}

export default ChatBox;
