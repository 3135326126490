import { Typography } from "@mui/material";
import clsx from "clsx";
import React, { useEffect } from "react";
import MentionedTextWrapper from "./MentionedTextWrapper";

export default function ExpandableText({ text }) {
  const [showDescription, setShowDescription] = React.useState(false);
  const [showSeeMore, setShowSeeMore] = React.useState(false);
  const [childrenLoaded, setChildrenLoaded] = React.useState(false);
  const ref = React.useRef(null);

  useEffect(() => {
    if (ref?.current) {
      setShowSeeMore(ref.current.scrollWidth !== ref.current.clientWidth);
    }
  }, [childrenLoaded]);
  return (
    <div>
      <Typography
        variant="body2"
        className={clsx(
          "break-words text-base",
          showDescription ? "" : "truncate text-nowrap"
        )}
        ref={ref}
      >
        <MentionedTextWrapper setChildrenLoaded={setChildrenLoaded}>
          {text}
        </MentionedTextWrapper>
      </Typography>
      {!showDescription && showSeeMore && (
        <Typography
          onClick={(e) => {
            e.stopPropagation();
            setShowDescription(true);
          }}
          variant="body2"
          className="shrink-0 cursor-pointer"
          color='primary'
        >
          See More
        </Typography>
      )}
    </div>
  );
}
