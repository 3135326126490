import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import axios from "../../MyAxios";
import { LOGIN, RESET_PASSWORD, USERNAME } from "../../constants/api";
import Button from "../../components/Button";
import LayoutEntrance from "../../LayoutEntrance";
import wave from "../../assets/img/wave.svg";
import desktopWave from "../../assets/img/desktopWave.svg";
import google from "../../assets/img/google.svg";
import facebook from "../../assets/img/facebook.svg";
import mentee from "@images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsLoggedIn } from "../../redux/userSlice";
import { resetPosts, setPage, setTotalPages } from "@redux/prevPostsSlice";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 1,
    borderColor: "#887E7E",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
  bottomImgDesktop: {
    position: "fixed",
    bottom: -200,
    right: 0,
    left: 0,
    maxHeight: "90%",
  },
  borderTop: {
    borderTop: `2px solid #000`,
    width: "30%",
  },
}));

const Login = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigation = useNavigate();

  const [height, setHeight] = useState(window.innerHeight);
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (location.state?.message) {
      const messageDisplayed = sessionStorage.getItem('messageDisplayed');
      if (!messageDisplayed) {
        toast.error(location.state.message);
        window.history.replaceState({}, '');
        sessionStorage.setItem('messageDisplayed', 'true');
      }
    }
  }, [location]);

  useEffect(() => {
    localStorage.removeItem('homepageCommunity');
    localStorage.removeItem('homepageGroups');

    dispatch(resetPosts([]));
    dispatch(setTotalPages(1));
    dispatch(setPage(1));

    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
    });
  }, []);

  return (
    <div>
      <div
        style={{ maxWidth: "700px" }}
        className="flex flex-col justify-center mx-auto p-8"
      >
        <div className="mt-36 md:mt-80">
          <img
            src={mentee}
            alt="mentee"
            className="mx-auto mb-8"
            style={{ height: "50px" }}
          />
          <Typography
            variant="h6"
            className={clsx(classes.textColor, "text-sm text-center")}
          >
            Your values, Your people, Your community
          </Typography>

          <Typography
            variant="h6"
            fontWeight={"bold"}
            className={clsx(classes.textColor, "text-center")}
          >
            Your Serkohls
          </Typography>
        </div>

        <div className="mt-3 z-50" onClick={() => navigation("/login-details")}>
          <Button>Login</Button>
        </div>
        <div className="mt-3 z-50" onClick={() => navigation(USERNAME)}>
          <Button>Join Now</Button>
        </div>
      </div>
      <div className={clsx(classes.bottomImg, "block md:hidden")}>
        <img src={wave} alt="Wave" className="z-0 w-full" />
      </div>
      <div className={clsx(classes.bottomImgDesktop, "md:block hidden")}>
        <img src={desktopWave} alt="Wave" className="z-0 w-full" />
      </div>
    </div>
  );
};

export default Login;
