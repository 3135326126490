import { Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import axios from "@MyAxios";
import { GET_ALL_USERS, GET_POSTS, SEARCH_GROUPS } from "@constants/api";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import SearchBar from "@components/Search/SearchBar";
import TabPane from "@components/TabPane";
import PostsList from "@components/Search/PostsList";
import UsersList from "@components/Search/UsersList";
import GroupsList from "@components/Search/GroupsList";
import StreamsIcon from "@images/streams.svg";
import GroupsIcon from "@images/groups.svg";
import PeopleIcon from "@images/people.svg";
import Loader from "@components/Loader";
import { useDispatch } from "react-redux";
import { setClickedPostId } from "@redux/prevPostsSlice";
import Keywords from "@components/Search/Keywords";
import logo from "@images/logo.png";
import CommunitiesList from "@components/Search/CommunitiesList";
import { toast } from "react-toastify";

function ResultCount({ icon, text, count, onViewAll, imgProps }) {
  return (
    <div className="flex gap-2 border-b-2 border-gray-100 py-4">
      <img src={icon} alt="icon" className="w-6 h-6" {...imgProps} />
      <Typography className="font-semibold">{"(" + count + ")"}</Typography>
      <Typography className="font-semibold">{text}</Typography>
      <Typography
        onClick={onViewAll}
        color={"primary"}
        className="cursor-pointer ml-auto"
      >
        View More
      </Typography>
    </div>
  );
}

const Search = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  // KEYWORDS STATE
  const [keywords, setKeywords] = useState([]);
  const [tags, setTags] = useState([]);
  const [keywordsLoading, setKeywordsLoading] = useState(false);
  // POSTS STATE
  const [posts, setPosts] = useState([]);
  const [totalPostPages, setTotalPostPages] = useState(0);
  const [postPage, setPostPage] = useState(1);
  const [postsCount, setPostsCount] = useState(0);
  const [postsLoading, setPostsLoading] = useState(false);

  // USERS STATE
  const [users, setUsers] = useState([]);
  const [totalUsersPages, setTotalUsersPages] = useState(0);
  const [userPage, setUserPage] = useState(1);
  const [usersCount, setUsersCount] = useState(0);
  const [usersLoading, setUsersLoading] = useState(false);

  // GROUPS STATE
  const [groups, setGroups] = useState([]);
  const [totalGroupsPages, setTotalGroupsPages] = useState(0);
  const [groupPage, setGroupPage] = useState(1);
  const [groupsCount, setGroupsCount] = useState(0);
  const [groupsLoading, setGroupsLoading] = useState(false);

  // COMMUNITIES STATE
  const [communities, setCommunities] = useState([]);
  const [totalCommunitiesPages, setTotalCommunitiesPages] = useState(0);
  const [communityPage, setCommunityPage] = useState(1);
  const [communitiesCount, setCommunitiesCount] = useState(0);
  const [communitiesLoading, setCommunitiesLoading] = useState(false);

  const activeTab = Number(
    searchParams.get("activeTab") === "null"
      ? "0"
      : searchParams.get("activeTab")
  );
  const search = searchParams.get("search");

  const [searchField, setSearchField] = React.useState(search || "");

  useEffect(() => {
    setSearchField(search);
  }, [search]);
  const setSearch = (search) => {
    if(search?.length < 3)
      return toast.error('You must enter at least 3 characters');

    if(!search)
      return;
    setPostPage(1);
    setUserPage(1);
    setGroupPage(1);
    setSearchParams(() => {
      return { search, activeTab };
    });
  };

  const setActiveTab = useCallback(
    (activeTab) => {
      setSearchParams(() => {
        return { activeTab, search };
      });
    },
    [setSearchParams, search]
  );

  const tabs = useMemo(() => ["All", "Streams", "Groups", "People", "Communities"], []);

  const navigate = useNavigate();

  const getPosts = useCallback(() => {
    setPostsLoading(true);
    axios
      .get(`${GET_POSTS}/${postPage}/${encodeURIComponent(search)}`)
      .then((res) => {
        setPostsLoading(false);
        if (postPage === 1) setPosts(res.data.posts);
        else setPosts((posts) => [...posts, ...res.data.posts]);
        setTotalPostPages(res.data.totalPages);
        setPostsCount(res.data.count);
      })
      .catch(() => {})
      .finally(() => {
        setPostsLoading(false);
      });
  }, [postPage, search]);

  const getGroups = useCallback(() => {
    setGroupsLoading(true);
    axios
      .get(`${SEARCH_GROUPS}/${groupPage}/${encodeURIComponent(search)}`)
      .then((res) => {
        if (groupPage === 1) setGroups(res.data.groups);
        else setGroups((groups) => [...groups, ...res.data.groups]);
        setTotalGroupsPages(res.data.totalPages);
        setGroupsCount(res.data.count);
      })
      .catch(() => {})
      .finally(() => {
        setGroupsLoading(false);
      });
  }, [groupPage, search]);

  const getCommunities = useCallback(() => {
    setCommunitiesLoading(true);
    axios
      .get(`search-communities/${communityPage}/${encodeURIComponent(search)}`)
      .then((res) => {
        if (communityPage === 1) setCommunities(res.data.communities);
        else setCommunities((communities) => [...communities, ...res.data.communities]);
        setTotalCommunitiesPages(res.data.totalPages);
        setCommunitiesCount(res.data.count);
      })
      .catch(() => {})
      .finally(() => {
        setCommunitiesLoading(false);
      });
  }, [communityPage, search]);

  const getUsers = useCallback(() => {
    setUsersLoading(true);
    axios
      .get(`${GET_ALL_USERS}/${userPage}/${encodeURIComponent(search)}`)
      .then((res) => {
        const data = res.data.users.filter(
          (u) => u.verification?.emailStatus === "confirmed"
        );
        if (userPage === 1) setUsers(data);
        else setUsers((users) => [...users, ...data]);
        setTotalUsersPages(res.data.totalPages);
        setUsersCount(res.data.count);
      })
      .catch(() => {})
      .finally(() => {
        setUsersLoading(false);
      });
  }, [userPage, search]);

  const onSearch = useCallback(() => {
    if (!search || search === "") return;
    addKeyword(search);
    getUsers();
    getGroups();
    getPosts();
    getCommunities();
  }, [search, getPosts, getUsers, getGroups, getCommunities]);

  useEffect(() => {
    onSearch();
  }, [onSearch]);

  // KEYWORDS
  const addKeyword = (search) => {
    axios.post("/keyword", { text: search }).then((res) => {
      if (res.data) setKeywords(res.data);
    });
  };
  useEffect(() => {
    setKeywordsLoading(true);
    axios
      .get("/keyword")
      .then((res) => {
        setKeywords(res.data);
        return axios.get("/tags");
      })
      .then((res) => {
        setTags(res.data);
      })
      .catch(() => {})
      .finally(() => setKeywordsLoading(false));
  }, []);
  const onDeleteKeywords = (ids) => {
    axios
      .post(`/keyword/delete`, { ids })
      .then((newKeywords) => {
        setKeywords(newKeywords.data);
      })
      .catch(() => {});
  };

  const goBack = () => navigate(-1);
  const onTabChange = (index) => {
    if (index >= 0 && index < tabs.length) setActiveTab(index);
  };
  if (usersLoading || postsLoading || groupsLoading || keywordsLoading)
    return <Loader />;
  return (
    <div className="md:w-1/2 mx-auto px-4">
      <div>
        <div className="flex items-center sticky top-0 h-14 bg-white z-50">
          <Link to="/" onClick={() => dispatch(setClickedPostId("top-page"))}>
            <img src={logo} alt="Logo" className="mr-3 w-28" />
          </Link>
          <div className="w-full">
            <SearchBar
              onBack={goBack}
              value={searchField}
              setValue={setSearchField}
              onClick={() => setSearch(searchField)}
              placeholder='Search (at least 3 characters)'
            />
          </div>
        </div>

        <div>
          {search && search !== "null" && (
            <TabPane
              tabs={tabs}
              activeTab={activeTab}
              onTabChange={onTabChange}
            >
              <div>
                <div>
                  <ResultCount
                    icon={PeopleIcon}
                    text={"People"}
                    count={usersCount}
                    onViewAll={() => setActiveTab(3)}
                    imgProps={{ className: "w-4" }}
                  />
                  <UsersList users={users.slice(0, 3)} />
                </div>

                <div>
                  <ResultCount
                    icon={GroupsIcon}
                    text={"Groups"}
                    count={groupsCount}
                    onViewAll={() => setActiveTab(2)}
                  />
                  <GroupsList groups={groups.slice(0, 3)} />
                </div>

                <div>
                  <ResultCount
                    icon={GroupsIcon}
                    text={"Communities"}
                    count={communitiesCount}
                    onViewAll={() => setActiveTab(4)}
                  />
                  <CommunitiesList communities={communities.slice(0, 3)} />
                </div>

                <div>
                  <ResultCount
                    icon={StreamsIcon}
                    text={"Streams"}
                    count={postsCount}
                    onViewAll={() => setActiveTab(1)}
                  />
                  <PostsList posts={posts.slice(0, 3)} />
                </div>
              </div>
              <PostsList
                posts={posts}
                page={postPage}
                totalPages={totalPostPages}
                onShowMore={() => setPostPage((x) => x + 1)}
              />
              <GroupsList
                groups={groups}
                page={groupPage}
                totalPages={totalGroupsPages}
                onShowMore={() => setGroupPage((x) => x + 1)}
              />
              <UsersList
                users={users}
                page={userPage}
                totalPages={totalUsersPages}
                onShowMore={() => setUserPage((x) => x + 1)}
              />
              <CommunitiesList
                communities={communities}
                page={communityPage}
                totalPages={totalCommunitiesPages}
                onShowMore={() => setCommunityPage((x) => x + 1)}
              />
            </TabPane>
          )}
        </div>
        <div>
          {(!search || search === "null") && (
            <Keywords
              keywords={keywords}
              tags={tags}
              setSearch={setSearch}
              onDeleteKeywords={onDeleteKeywords}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;
