import React, { useEffect, useState } from "react";
import { Avatar, Typography } from "@mui/material";
import Post from "./Post";
import { useNavigate } from "react-router-dom";
import addPost from "../assets/img/addPost2.svg";
import { CREATE_POST, GET_GROUP_POSTS } from "@constants/api";
import axios from "@MyAxios";
import { useDispatch, useSelector } from "react-redux";
import {
  addToGroups,
  resetPost,
  setIsGroupOnlyPost,
} from "@redux/postSlice";
import ShowMore from "@components/ShowMore";
import { removeCommunityGroupPost } from "@redux/communitySlice";
import { toast } from "react-toastify";
import { setPage, setPosts, setTotalPages } from "@redux/groupSlice";
import { setClickedPostId } from "@redux/prevPostsSlice";

function GroupStreams({ group }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const groupId = group?.id;

  const { userRecord } = useSelector((state) => state.user);
  const { clickedPostId } = useSelector(state => state.prevPosts);

  const { page, totalPages, posts } = useSelector((state) => state.group);
  const pageRef = React.useRef(page);
  const postsRef = React.useRef(posts);
  const totalPagesRef = React.useRef(totalPages);

  const post = useSelector((state) => state.post);

  const getPosts = async () => {
    try {
      if(!groupId)
        return;

      await axios.get(`${GET_GROUP_POSTS}/${groupId}`, {
        params: { page, limit: 15 },
      })
        .then((res) => {
          dispatch(setPosts([ ...posts, ...res.data.posts ]));
          dispatch(setTotalPages(res.data.totalPages));
        });
    } catch (error) {}
  };

  const refetchPosts = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
  
  useEffect(() => {
    const interval = setInterval(() => {
      let postElement;
      if (clickedPostId) {
        postElement = document.getElementById(clickedPostId);
      }
      if (postElement) {
        clearInterval(interval);
        postElement.scrollIntoView({ behavior: "smooth" });
        dispatch(setClickedPostId(''));
      }
    }, 100);
  }, [clickedPostId]);

  const onCreateGroupPost = () => {
    if(group?.disabledAt > 0)
      return toast.error('Group is disabled');

    if(group?.community?.disabledAt > 0)
      return toast.error('Group community is disabled');

    dispatch(resetPost());
    dispatch(setIsGroupOnlyPost(true));
    dispatch(addToGroups(groupId));
    navigate(CREATE_POST);
  };

  const handleRemoveCommunityGroupPost = (postId) => {
    if(window.confirm('Are you sure you want to remove this post from group?')) {
      dispatch(removeCommunityGroupPost({
        data: {
          postId,
          groupId,
          communityId: group?.community?.id
        },
        onSuccess: refetchPosts
      }))
    }
  }

  useEffect(() => {
    if(localStorage[`group-${groupId}-cache`]) {
      let groupCache = JSON.parse(localStorage[`group-${groupId}-cache`]);

      dispatch(setPage(groupCache.page));
      dispatch(setPosts(groupCache.posts));
      dispatch(setTotalPages(groupCache.totalPages));
      delete localStorage[`group-${groupId}-cache`];

    } else {
      getPosts();
    }
  }, [groupId, page]);

  useEffect(() => {
    pageRef.current = page;
    postsRef.current = posts;
    totalPagesRef.current = totalPages;
  }, [page, posts, totalPages]);

  const cachePosts = () => {
    localStorage.setItem(`group-${groupId}-cache`, JSON.stringify({
      page: pageRef.current,
      posts: postsRef.current,
      totalPages: totalPagesRef.current,
    }));
  }

  useEffect(() => {
    return () => {
      if(localStorage.postClickTrigger)
        cachePosts();

      dispatch(setPosts([]));
      dispatch(setPage(1));
      dispatch(setTotalPages(1));
    }
  }, []);

  const userCanRemovePostsAsCommunityAdmin = group?.community?.adminAccessLevels?.canDeleteCommunityPost && group?.community?.admin === userRecord?.id;
  const communityOrGroupDisabled = group?.disabledAt > 0 || group?.community?.disabledAt > 0;
  const groupAdminCanDeletePost = group?.admin?.id === userRecord?.id;

  return (
    <div>
      {posts?.length === 0 && (
        <Typography className="mt-4">No streams yet...</Typography>
      )}
      {posts?.map((item) => (
        <div key={item.id} id={`post-${item.id}`}>
          <Post
            comment
            post={item}
            canDeletePost={groupAdminCanDeletePost}
            removePost={refetchPosts}
            readOnly={communityOrGroupDisabled}
            canRemoveCommunityGroupPost={userCanRemovePostsAsCommunityAdmin}
            handleRemoveCommunityGroupPost={handleRemoveCommunityGroupPost}
          />
        </div>
      ))}

      {page < totalPages && (
        <div
          onClick={() => {
            dispatch(setPage(page + 1));
          }}
          className="cursor-pointer my-2 text-center mx-4"
        >
          <ShowMore>Show More</ShowMore>
        </div>
      )}
      {(group?.members?.map((x) => x.id).includes(userRecord.id) ||
        group?.admin?.id === userRecord.id) && (
        <div
          onClick={onCreateGroupPost}
          className="fixed bottom-10 right-5 md:right-1/4 cursor-pointer"
        >
          <Avatar className="h-12 w-12 md:h-20 md:w-20" src={addPost} />
        </div>
      )}
    </div>
  );
}

export default GroupStreams;
