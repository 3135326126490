import React, { useEffect, useRef, useState } from 'react'
import { ClickAwayListener, Dialog, Fade, Icon, Paper, Popper, selectClasses } from '@mui/material'
import * as unicodeEmoji from 'unicode-emoji';
import { makeStyles } from '@mui/styles';
import { EmojiSearchField } from './EmojiSearchField';
import {
  BackHandOutlined as BackHandOutlinedIcon,
  Close as CloseIcon,
  Search as SearchIcon
} from '@mui/icons-material';
import clsx from 'clsx';
import smileys_people from '@images/emoji-tabs/smileys_people.svg';
import animals from '@images/emoji-tabs/animals.svg';
import food_drink from '@images/emoji-tabs/food_drink.svg';
import activity from '@images/emoji-tabs/activity.svg';
import travel_places from '@images/emoji-tabs/travel_places.svg';
import objects from '@images/emoji-tabs/objects.svg';
import symbols from '@images/emoji-tabs/symbols.svg';
import flag from '@images/emoji-tabs/flag.svg';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 12,
    color: '#ADAEB3',
    margin: 0
  },
  scrollbar: {
    scrollbarWidth: 'thin',
  },
  variationsSign: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 0 6px 6px',
    borderColor: 'transparent transparent grey transparent',
    position: 'absolute',
    right: 2,
    bottom: 0,
  }
}))

function EmojiModal({
  open = false,
  onClose,
  onSelection
}) {
  const allEmojies = unicodeEmoji.getEmojisGroupedBy('group');

  const classes = useStyles();

  const emojisRef = useRef();

  const [group, setGroup] = useState('smileys-emotion');
  const [searchText, setSearchText] = useState('');
  const [emojiUsage, setEmojiUsage] = useState([]);

  const [skinToneSelectorAnchor, setSkinToneSelectorAnchor] = useState();
  const [skinToneSelections, setSkinToneSelections] = useState();

  const updateEmojiUsage = (emoji) => {
    let emojiUsage = localStorage.emojiUsage ?? '[]';
    emojiUsage = JSON.parse(emojiUsage);

    const targetEmojiIndex = emojiUsage.findIndex(e => e.emoji == emoji);
    let targetEmoji = emojiUsage[targetEmojiIndex];

    if(!targetEmoji) {
      targetEmoji = {
        count: 1,
        emoji,
        timestamp: Date.now()
      }
      emojiUsage.push(targetEmoji);

    } else {
      targetEmoji.count++;
      targetEmoji.timestamp = Date.now();
      emojiUsage[targetEmojiIndex] = targetEmoji;
    }

    localStorage.setItem('emojiUsage', JSON.stringify(emojiUsage));
  }

  const handleEmojiClick = (emojiObj, event) => {
    handleCloseSkinTonesSelector();

    // if emoji is already selected, close the skin tone selector only
    if(Boolean(skinToneSelectorAnchor) && emojiObj.emoji === skinToneSelections.emoji)
      return;

    if(emojiObj.variations?.length) {
      setSkinToneSelections(emojiObj);
      setSkinToneSelectorAnchor(event.currentTarget);

    } else {
      updateEmojiUsage(emojiObj.emoji);
      onSelection?.(emojiObj.emoji);
      onClose();
    }
  }

  const handleCloseSkinTonesSelector = () => {
    // setSkinToneSelections();
    setSkinToneSelectorAnchor();
  }

  const CustomIcon = ({ svgIcon }) => {
    return (
      <Icon className='flex justify-center items-center'>
        <img src={svgIcon} />
      </Icon>
    );
  }

  const tabs = [
    {
      icon: <CustomIcon svgIcon={smileys_people} />,
      name: 'Smileys & Emotion',
      group: 'smileys-emotion'
    },
    {
      icon: <BackHandOutlinedIcon fontSize='small' />,
      name: 'People & Body',
      group: 'people-body'
    },
    {
      icon: <CustomIcon svgIcon={animals} />,
      name: 'Animals & Nature',
      group: 'animals-nature'
    },
    {
      icon: <CustomIcon svgIcon={food_drink} />,
      name: 'Food & Drink',
      group: 'food-drink'
    },
    {
      icon: <CustomIcon svgIcon={activity} />,
      name: 'Activity',
      group: 'activities'
    },
    {
      icon: <CustomIcon svgIcon={travel_places} />,
      name: 'Travel & Places',
      group: 'travel-places'
    },
    {
      icon: <CustomIcon svgIcon={objects} />,
      name: 'Objects',
      group: 'objects'
    },
    {
      icon: <CustomIcon svgIcon={symbols} />,
      name: 'Symbols',
      group: 'symbols'
    },
    {
      icon: <CustomIcon svgIcon={flag} />,
      name: 'Flags',
      group: 'flags'
    },
  ];

  const resetStates = () => {
    setGroup('smileys-emotion');
    setSearchText('');
  }

  useEffect(() => {
    if(emojisRef.current) {
      emojisRef.current.scrollTop = 0;
    }
  }, [group, emojisRef]);

  useEffect(() => {
    setEmojiUsage(JSON.parse(localStorage.emojiUsage ?? '[]'));

    if(!open)
      resetStates();
  }, [open]);

  const emojisToRender = allEmojies[group]
    .filter((emojiObj) =>
      emojiObj?.description?.toLowerCase()?.includes(searchText.toLowerCase())
      || emojiObj.keywords?.some(keyword => keyword.toLowerCase().includes(searchText.toLowerCase()))
    );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      PaperProps={{ className: 'rounded-xl bg-[#EAECEF] w-[256px]' }}
    >
      <div className='p-3'>
        <div className='flex items-center justify-between mb-4 gap-4'>
          <EmojiSearchField
            size='small'
            placeholder='Search'
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              startAdornment: <SearchIcon className='mr-2 text-red' />
            }}
          />

          <CloseIcon
            fontSize='small'
            className='cursor-pointer'
            onClick={onClose}
          />
        </div>

        {emojiUsage?.length > 0 && (
          <>
            <p className={classes.label}>
              Most Used
            </p>

            <div className={clsx('grid grid-cols-6 noto-font text-2xl max-h-[96px] overflow-y-auto mb-2', classes.scrollbar)}>
              {emojiUsage
                .reverse()
                .sort((a, b) => b.count - a.count > 0 ? 1 : -1)
                .filter((_, index) => index < 12)
                .map((emoji, index) => (
                  <span
                    key={index}
                    className='flex flex-col items-center justify-center cursor-pointer'
                    onClick={() => handleEmojiClick({ emoji: emoji.emoji })}
                  >
                    {emoji.emoji}
                  </span>
                ))
              }
            </div>
          </>
        )}

        <p className={classes.label}>
          Powered by Noto Color Emoji Font
        </p>

        <div
          ref={emojisRef}
          id="main-emoji-list"
          className={clsx('grid grid-cols-6 noto-font text-2xl max-h-[160px] overflow-y-auto mb-2', classes.scrollbar)}
          onScroll={() => handleCloseSkinTonesSelector()}
        >
          {emojisToRender?.length === 0 && (
            <div className='col-span-6 flex items-center'>
              <p className={classes.label}>No emojis found</p>
            </div>
          )}

          {emojisToRender.map((emojiObj, index) => (
            <span
              key={index}
              alt={emojiObj?.description}
              className='flex flex-col items-center justify-center cursor-pointer relative'
              onClick={(e) => handleEmojiClick(emojiObj, e)}
            >
              {emojiObj?.emoji}

              {Boolean(emojiObj?.variations?.length) && (
                <div className={classes.variationsSign} />
              )}
            </span>
          ))}

        </div>

        <div className='flex items-center justify-between gap-2 px-1'>
          <div className='flex items-center justify-evenly'>
            {tabs.map((tab, index) => (
              <div
                key={index}
                title={tab.name}
                onClick={() => setGroup(tab.group)}
                className={clsx(
                  'flex flex-col items-center justify-center cursor-pointer rounded-full p-[2px]',
                  tab.group === group && 'bg-[#C4C5C8]'
                )}
              >
                {tab.icon}
              </div>
            ))}
          </div>
        </div>
      </div>

      <Popper
        sx={{ zIndex: 999999 }}
        open={Boolean(skinToneSelectorAnchor)}
        anchorEl={skinToneSelectorAnchor}
        placement='right'
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={(e) => {
            const target = e.target;
            const emojiElements = document.querySelectorAll('#main-emoji-list > *');
            const isEmojiClicked = Array.from(emojiElements).some(emojiElement => emojiElement.contains(target));
            if(!isEmojiClicked) {
              handleCloseSkinTonesSelector();
            }
          }}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper className='rounded-lg p-1 grid grid-cols-5 gap-1.5 text-2xl max-h-[170px] overflow-y-auto'>
                {skinToneSelections?.variations?.map((emoji, index) => (
                  <span
                    key={index}
                    alt={emoji?.description}
                    className='flex flex-col items-center justify-center cursor-pointer'
                    onClick={(e) => handleEmojiClick(emoji, e)}
                  >
                    {emoji?.emoji}
                  </span>
                ))}
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Dialog>
  )
}

export default EmojiModal