import { Modal, Button } from "@mui/material";
import { ModalBox } from "@components/admin/StyledComponents";
import TagsForm from "@components/admin/TagsForm";
import Tag from "@components/admin/Tag";
import { useForm } from "react-hook-form";
import { Avatar, Typography } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

import camera from "@images/camera.svg";
import DownloadIcon from "@images/download.svg";
import { useEffect, useRef, useState } from "react";
import api from "@apiClient";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 2,
  },
}));

export default function CommunityUpdateForm({
  open,
  setOpen,
  community,
  setReload,
  communityRefresh
}) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [file, setFile] = useState();
  const [image, setImage] = useState();
  const [tags, setTags] = useState([]);
  const classes = useStyles();
  const fileInput = useRef(null);

  const onTagRemove = (key) => {
    setTags(tags.filter((_, tagKey) => key !== tagKey));
  };

  useEffect(() => {
    api.getTagsByCommunity(community.id).then((tags) => {
      setTags(tags);
    });
  }, []);
  const clearFormData = () => {
    setValue("name", community.name);
    setValue("description", community.description);
    setImage(community.icon?.avatarUrl);
    setFile(null);
  };

  useEffect(() => {
    clearFormData();
  }, [community]);

  const onSubmit = (data) => {
    setOpen(false);
    const formData = new FormData();
    formData.append("id", community.id);
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("file", file);
    Promise.all([
      api
        .updateCommunity(formData)
        .then(() => {
          toast.success("Community updated successfully");
        })
        .catch(() => {}),
      api.updateTags({ communityId: community.id, tags }).catch(() => {})
    ])
      .then(() => {
        setReload((r) => r + 1);
        communityRefresh?.();
      })
  };

  const onImageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const writeTagsToFile = () => {
    const tagsString = tags.map((tag) => tag.subcategory).join(",");
    const blob = new Blob([tagsString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "tags.csv";
    a.click();
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <ModalBox sx={{ p: 4 }} className="rounded-lg shadow-md">
        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          className="flex flex-col gap-2"
        >
          <div className="flex items-center mb-6">
            <div
              className=" relative"
              onClick={() => fileInput.current.click()}
            >
              <Avatar
                className=" relative h-20 w-20 object-contain"
                src={image}
              />
              <img
                className=" absolute bottom-0 right-0"
                src={camera}
                alt="Camera"
              />
            </div>
            <Typography className="ml-4">Community icon (Optional)</Typography>
          </div>
          <div className="grid grid-cols-2 gap-6">
            <div>
              <input
                onChange={onImageChange}
                ref={fileInput}
                type="file"
                className="hidden"
              />
              <div className="input-wrapper flex flex-col">
                <label className="mb-2 font-medium">Community Name:</label>
                <input
                  className={clsx(classes.border, "rounded-md p-3")}
                  placeholder="Name"
                  type="text"
                  {...register("name", {
                    required: "Name is required",
                  })}
                />
                {errors.title && (
                  <p className="text-xs italic text-red-500">
                    {errors.title.message}
                  </p>
                )}
              </div>

              <div className="input-wrapper flex flex-col">
                <label className="my-4 font-medium">
                  Community Description:
                </label>
                <textarea
                  className={clsx(classes.border, "h-32 rounded-md p-3")}
                  placeholder="Description"
                  {...register("description", {
                    required: "Description is required",
                  })}
                />
                {errors.description && (
                  <p className="text-xs italic text-red-500">
                    {errors.description.message}
                  </p>
                )}
              </div>
            </div>
            <div>
              <TagsForm setTags={setTags} tags={tags} />
              <div className="flex mt-4 mr-14 gap-2 items-center">
                <label className=" font-medium">Your Tags:</label>{" "}
                <div className="text-sm ml-auto">Download Tags</div>
                <img
                  onClick={writeTagsToFile}
                  className="cursor-pointer"
                  src={DownloadIcon}
                  alt="download"
                />
              </div>
              <div className="flex mt-4 flex-wrap gap-2 max-h-40 overflow-auto">
                {tags.map((tag, key) => (
                  <Tag
                    name={tag.subcategory}
                    key={key}
                    // onRemove={() => onTagRemove(key)}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="flex w-full justify-end gap-2 mt-6">
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button type="submit" variant="contained">
              Update
            </Button>
          </div>
        </form>
      </ModalBox>
    </Modal>
  );
}
