import { TextField } from "@mui/material";
import { styled } from "@mui/styles";

export const EmojiSearchField = styled(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    borderRadius: 8,
    "& fieldset": {
      border: "none",
    },
  },
}));
