import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LayoutEntrance from "../../LayoutEntrance";
import { toast } from "react-toastify";
import axios from "../../MyAxios";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import BottomText from "../../components/BottomText";
import Constraints from "../../components/Constraints";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import mentee from "@images/logo.png";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 1,
    borderColor: "#887E7E",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
}));

const Signup = () => {
  const classes = useStyles();
  const { user } = useSelector((state) => state.user);
  const [disabledSU, setDisabledSU] = useState(false);
  const navigation = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    // Retrieve form data from localStorage and set state
    const storedFormData = JSON.parse(localStorage.getItem("formData")) || {};
    setValue("userName", user || storedFormData.userName);
    setValue("emailAddress", storedFormData.email || "");
    setValue("phoneNumber", storedFormData.phone || "");
    setValue("firstName", storedFormData.firstName || "");
    setValue("lastName", storedFormData.lastName || "");
    setValue("aliasOne", storedFormData.aliasOne || "");
    setValue("aliasTwo", storedFormData.aliasTwo || "");
    setValue("age", storedFormData.age || "");
    setValue("dateOfBirth", storedFormData.dateOfBirth || "");
  }, []);

  // Form submission handler
  const onSubmit = async (data) => {
    const formData = {
      userName: watch("userName"),
      phone: watch("phoneNumber"),
      firstName: watch("firstName"),
      lastName: watch("lastName"),
      email: watch("emailAddress"),
      aliasOne: watch("aliasOne"),
      aliasTwo: watch("aliasTwo"),
      age: watch("age"),
      dateOfBirth: watch("dateOfBirth"),
      password: watch("password")
    };
    localStorage.setItem("formData", JSON.stringify(formData));

    if (disabledSU) {
      console.log("disable su", disabledSU);
      toast.error("Please meet all the requirements");
    } else {
      try {
        navigation('/enter/select-communities');
      } catch (error) {
        console.log(error);
      }
    }
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const setDisabled = (e) => console.log();

  return (
    <LayoutEntrance>
      <Typography
        variant="h4"
        className={clsx(classes.textColor, " text-center font-extrabold mt-4")}
      >
        Sign Up for
      </Typography>
      <img
        src={mentee}
        alt="mentee"
        className="mx-auto mb-2"
        style={{ height: "30px" }}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
        className="flex flex-col gap-2"
      >
        <div className="input-wrapper flex flex-col">
          <label className="ml-1">
            <small>Create your password *</small>
          </label>
          <OutlinedInput
            size="small"
            placeholder="Password"
            type={showPassword ? "text" : "password"}
            inputProps={{
              ...register("password", {
                required: "Password is required",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters",
                },
              }),
              className: "p-3",
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />

          {errors.password && (
            <p className="text-xs italic text-red-500">
              {errors.password.message}
            </p>
          )}
        </div>
        <div className="input-wrapper flex flex-col">
          <label className="ml-1">
            <small>Reenter your password*</small>
          </label>
          <OutlinedInput
            size="small"
            placeholder="Reenter Password"
            type={showConfirmPassword ? "text" : "password"}
            inputProps={{
              ...register("confirmPassword", {
                required: "Please confirm your password",
                validate: (value) =>
                  value === watch("password") || `Passwords do not match`,
              }),
              className: "p-3",
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />

          {errors.confirmPassword && (
            <p className="text-xs italic text-red-500">
              {errors.confirmPassword.message}
            </p>
          )}
        </div>

        <Constraints
          string={watch("password")}
          setDisabledSU={setDisabledSU}
          setDisabled={setDisabled}
          forPass={true}
        />

        <div className="mt-14">
          <Button>Continue</Button>
        </div>
      </form>
    </LayoutEntrance>
  );
};

export default Signup;
