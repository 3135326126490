import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { resetPosts, setPage } from '@redux/prevPostsSlice';

function useHomepageReset() {
  const location = useLocation();
  const dispatch = useDispatch();

  const { clickedPostId } = useSelector(state => state.prevPosts);

  const [prevPage, setPrevPage] = useState();
  const [currPage, setCurrPage] = useState();

  const homepageSustainRoutes = [
    '/search'
  ];

  useEffect(() => {
    // If routing to anything in homepageSustainRoutes from somewhere other than homepage
    if(homepageSustainRoutes.includes(currPage) && prevPage !== '/' && prevPage !== '/stream') {
      if(!clickedPostId) {
        dispatch(resetPosts());
        dispatch(setPage(1));
      }
    }
  }, [currPage, prevPage]);

  useEffect(() => {
    setPrevPage(currPage);
    setCurrPage(location.pathname);
  }, [location]);
}

export default useHomepageReset;