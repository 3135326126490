import { Avatar } from "@mui/material";
import CommentText from "./CommentText";
import { useState } from "react";
import { calTime } from "../helpers";
import { Link, useNavigate } from "react-router-dom";
import { REPORT_FORM } from "@constants/api";
import { useSelector } from "react-redux";

function CommentOptions({ children, onClick }) {
  return (
    <span
      onClick={onClick}
      style={{ fontSize: "12px" }}
      className="font-normal cursor-pointer mr-1 text-gray-500"
    >
      {children}
    </span>
  );
}

export default function Comment({
  readOnly = false,
  comment,
  post,
  setCommentToReply,
  setCommentToEdit,
  setComment,
  onDelete,
  setPrevFile,
  overwritable,
  overwriteLevel,
  setCommentToOverwrite
}) {
  const [showReply, setShowReply] = useState(true);
  const navigate = useNavigate();
  const { userRecord } = useSelector((state) => state.user);

  const onCommentEdit = (comment) => {
    setCommentToEdit(comment);

    setComment(comment?.comment);
    setPrevFile(comment?.media?.avatarUrl);
  };

  const onCommentOverwrite = (comment) => {
    setCommentToOverwrite(comment);

    setComment(comment?.comment);
    setPrevFile(comment?.media?.avatarUrl);
  };

  const isGroupPost = post?.groups?.length >= 1;
  const userIsPostGroupAdmin = isGroupPost && post.groups.some(group => group.admin === userRecord.id);

  const commentUserToShow = comment?.overwrittenBy ?? comment?.user;

  return (
    <div className="flex flex-col">
      <div className="flex">
        <Link to={`/${commentUserToShow?.userName}`}>
          <Avatar
            src={commentUserToShow?.media?.avatarUrl}
            className="z-0 mr-2 mt-3 self-start"
          />
        </Link>
        <div className="flex flex-col grow mr-12">
          <div className="flex items-center">
            <CommentText
              readOnly={readOnly}
              user={commentUserToShow}
              media={comment.media}
              time={calTime(comment?.createdAt)}
              onEdit={() => onCommentEdit(comment)}
              overwrittenBy={comment?.overwrittenBy}
            >
              {comment.comment}
            </CommentText>
          </div>
          
          {readOnly && <div className="mb-6" />}

          <div className={readOnly ? 'hidden' : ''}>
            {overwritable && (
              <>
                <CommentOptions
                  onClick={() => onCommentOverwrite(comment)}
                >
                  Overwrite
                </CommentOptions>

                <CommentOptions>|</CommentOptions>
              </>
            )}

            <CommentOptions
              onClick={() => {
                setComment(
                  `@[${commentUserToShow?.userName}](${commentUserToShow?.userName}) `
                );

                setCommentToReply(comment);
              }}
            >
              Reply
            </CommentOptions>
            {userRecord?.id === commentUserToShow?.id && (
              <CommentOptions>|</CommentOptions>
            )}

            {userRecord?.id === commentUserToShow?.id && (
              <CommentOptions
                onClick={() => {
                  onDelete(comment?.id);
                }}
              >
                Delete
              </CommentOptions>
            )}
            <CommentOptions>|</CommentOptions>
            <CommentOptions
              onClick={() =>
                navigate(REPORT_FORM, {
                  state: { id: comment?.id, report: "comment" },
                })
              }
            >
              Report
            </CommentOptions>
          </div>
          {/* {!showReply && comment?.replies?.length > 0 && (
            <CommentOptions
              className=" font-normal cursor-pointer text-gray-500"
              onClick={() => setShowReply(true)}
            >
              ____View Replies____
            </CommentOptions>
          )}

          {showReply && comment?.replies?.length > 0 && (
            <CommentOptions
              className=" font-normal cursor-pointer text-gray-500"
              onClick={() => setShowReply(false)}
            >
              ____Collapse____
            </CommentOptions>
          )} */}
        </div>
      </div>

      {showReply &&
        comment?.replies?.map((reply, key) => {     
          const replyUserToShow = reply?.overwrittenBy ?? reply?.user;
          const replyOverwritable = userIsPostGroupAdmin && reply.user.id !== userRecord.id && reply.overwrittenBy?.id !== userRecord.id;

          return (
            <div key={key} className="ml-10 mt-2 flex">
              <Avatar
                src={replyUserToShow?.media?.avatarUrl}
                className="z-0 mr-2 mt-3"
              />
              <div className="flex flex-col grow mr-6">
                <div className="flex items-center">
                  <CommentText
                    media={reply.media}
                    user={replyUserToShow}
                    time={calTime(reply?.createdAt)}
                    onEdit={() => onCommentEdit(reply)}
                    overwrittenBy={reply?.overwrittenBy}
                  >
                    {reply?.comment}
                  </CommentText>
                </div>
                <div>
                  {replyOverwritable && (
                    <>
                      <CommentOptions
                        onClick={() => onCommentOverwrite(reply)}
                      >
                        Overwrite
                      </CommentOptions>

                      <CommentOptions>|</CommentOptions>
                    </>
                  )}

                  <CommentOptions
                    onClick={() => {
                      setComment(`@${replyUserToShow?.userName} `);
                      setCommentToReply(comment);
                    }}
                  >
                    Reply
                  </CommentOptions>

                  {userRecord?.id === replyUserToShow?.id && (
                    <CommentOptions>|</CommentOptions>
                  )}

                  {userRecord?.id === replyUserToShow?.id && (
                    <CommentOptions
                      onClick={() => {
                        onDelete(reply?.id);
                      }}
                    >
                      Delete
                    </CommentOptions>
                  )}

                  <CommentOptions>|</CommentOptions>

                  <CommentOptions
                    onClick={() =>
                      navigate(REPORT_FORM, {
                        state: { id: comment?.id, report: "comment" },
                      })
                    }
                  >
                    Report
                  </CommentOptions>
                </div>
              </div>
            </div>
          )
        })}
    </div>
  );
}
